/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:47:44
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:48:14
 */
import Tradepackage from './tradepackage.vue'
export default Tradepackage
