/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 17:30:08
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:57:41
 */

import Diypackage from './components/diypackage'
import Tagpackage from './components/tagpackage'
import Tradepackage from './components/tradepackage'
import Expandpackage from './components/expandpackage'

export default {
  name: 'Datapackage',
  components: {
    Diypackage,
    Tagpackage,
    Tradepackage,
    Expandpackage
  },
  data () {
    return {
      activeTab: 'diy'
    }
  }
}
