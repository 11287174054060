/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:47:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:48:43
 */
export default {
  name: 'Tradepackage'
}
