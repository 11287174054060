/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:42:12
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:43:47
 */
import Tagpackage from './tagpackage.vue'
export default Tagpackage
