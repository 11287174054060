/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:42:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:42:57
 */
export default {
  name: 'Tagpackage'
}
