/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:37:09
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:39:53
 */
import Diypackage from './diypackage.vue'
export default Diypackage
