/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 17:30:28
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 17:31:03
 */
import Datapackage from './Datapackage.vue'
export default Datapackage
